export interface RedirectPage {
    path: string;
    code: number;
}

export const UNAUTHORIZED: RedirectPage = {
    path: 'unauthorized',
    code: 401
};

export const FORBIDDEN: RedirectPage = {
    path: 'forbidden',
    code: 403
};

export const NOT_FOUND: RedirectPage = {
    path: 'not-found',
    code: 404
};
